export const prices = [
    {
        _id: 0,
        name: "Any",
        array: [],
    },
    {
        _id: 1,
        name: "₹0 to ₹499",
        array: [0, 499],
    },
    {
        _id: 2,
        name: "₹500 to ₹999",
        array: [500, 999],
    },
    {
        _id: 3,
        name: "₹1000 to ₹1499",
        array: [1000, 1499],
    },
    {
        _id: 4,
        name: "₹1500 to ₹1999",
        array: [1500, 1999],
    },
    {
        _id: 5,
        name: "More than ₹2000",
        array: [2000, 1500000],
    },
]